import React, { FC, useCallback } from "react";

import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import { registration } from "@/actions/account.actions";

import { Form, Input, Checkbox } from "antd";
import { Rule } from "antd/es/form";

import css from "./style.modules.scss";

import AuthLayout, { defaultInputProps } from "@/components/AuthLayout";
import useFormValidation from "@/hooks/useFormValidation";

const FIELDS_RULES: Record<string, Rule[]> = {
  firstName: [
    {
      pattern: /^[a-zA-Zа-яА-ЯёЁ]+$/,
      message: "Имя должно содержать только буквы",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, введите ваше имя"));
        }

        return Promise.resolve();
      },
    },
  ],
  lastName: [
    {
      pattern: /^[a-zA-Zа-яА-ЯёЁ]+$/,
      message: "Фамилия должна содержать только буквы",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, введите вашу фамилию"));
        }

        return Promise.resolve();
      },
    },
  ],
  email: [
    {
      pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, // sky.net-123@example-domain.com
      message: "Некорректный формат email",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, введите ваш email"));
        }

        return Promise.resolve();
      },
    },
  ],
  companyInn: [
    {
      pattern: /^[0-9]{10,12}$/,
      message: "ИНН компании должен содержать от 10 до 12 цифр",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, введите ИНН компании"));
        }

        return Promise.resolve();
      },
    },
  ],
  acceptDataProcessing: [
    {
      validator: (_, value) => {
        return value ? Promise.resolve() : Promise.reject(new Error("Вы должны принять обработку данных"));
      },
    },
  ],
  password: [
    {
      required: true,
      message: "Пожалуйста, введите пароль"
    },
    {
      pattern: /(?=\S*?[A-Z])/,
      message: "Должен содержать хотя бы одну заглавную букву",
    },
    {
      pattern: /(?=\S*?[a-z])/,
      message: "Должен содержать хотя бы одну строчную букву",
    },
    {
      pattern: /(?=\S*?[0-9])/,
      message: "Должен содержать хотя бы одну цифру",
    },
    {
      pattern: /^[^\s]+$/,
      message: "Пароль не должен содержать пробелов",
    },
    {
      pattern: /^[a-zA-Z0-9\s]*$/,
      message: "Должен содержать только латинские буквы и цифры",
    },
    {
      pattern: /.{8,}/,
      message: "Должен быть длиной как минимум 8 символов",
    }
  ],
  confirmPassword: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, повторите пароль"));
        }

        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }

        return Promise.reject("Пароли не совпадают");
      },
    }),
  ]
};

interface IFormFields {
  firstName: string;
  lastName: string;
  email: string;
  companyInn: string;
  password: string;
  confirmPassword: string;
  acceptDataProcessing: boolean;
};

interface IRegistrationForm { }

const RegistrationForm: FC<IRegistrationForm> = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm<IFormFields>();
  const { isDisabled } = useFormValidation(form);

  const closeRegistrationForm = (): void => {
    navigate("/login");
  };

  const onClickRegistrationHandler = useCallback((): void => {
    form
      .validateFields()
      .then((values: IFormFields) => {
        dispatch(registration(values));

        closeRegistrationForm();
      });
  }, [form, dispatch]);

  return (
    <AuthLayout
      title="Регистрация"
      message="Заполните ваши контактные данные и ИНН вашей компании."
      buttonText="Зарегистрироваться"
      onButtonClick={onClickRegistrationHandler}
      buttonDisabled={isDisabled}
      formSize="large"
      actions={(
        <div className="flex flex-row gap-6 justify-center">
          <span className="flex mobile:flex-col mobile:justify-center gap-1 text-n3">
            У вас уже есть аккаунт?
            <a className={`${css.link} m-0 p-0 text-center`} onClick={closeRegistrationForm}>
              Войдите
            </a>
          </span>
        </div>
      )}
    >
      <Form
        form={form}
        name="registrationForm"
        className="flex flex-col gap-3 m-0"
      >
        <div className="flex flex-row gap-2">
          <Form.Item
            className="m-0 w-full"
            name="firstName"
            rules={FIELDS_RULES.firstName}
          >
            <Input {...defaultInputProps} placeholder="Имя" />
          </Form.Item>
          <Form.Item
            className="m-0 w-full"
            name="lastName"
            rules={FIELDS_RULES.lastName}
          >
            <Input {...defaultInputProps} placeholder="Фамилия" />
          </Form.Item>
        </div>
        <div className="flex flex-row gap-2">
          <Form.Item
            className="m-0 w-full"
            name="email"
            rules={FIELDS_RULES.email}
          >
            <Input {...defaultInputProps} placeholder="Email" />
          </Form.Item>
          <Form.Item
            className="m-0 w-full"
            name="companyInn"
            rules={FIELDS_RULES.companyInn}>
            <Input {...defaultInputProps} placeholder="ИНН компании" />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-2">
          <Form.Item
            className="m-0 w-full"
            name="password"
            rules={FIELDS_RULES.password}
          >
            <Input.Password
              {...defaultInputProps}
              placeholder="Введите пароль"
            />
          </Form.Item>
          <Form.Item
            className="m-0 w-full"
            name="confirmPassword"
            dependencies={["password"]}
            rules={FIELDS_RULES.confirmPassword}
          >
            <Input.Password
              {...defaultInputProps}
              placeholder="Повторите пароль"
            />
          </Form.Item>
        </div>
        <div className="flex flex-col">
          <Form.Item
            className="m-0 w-full"
            name="acceptDataProcessing"
            valuePropName="checked"
            rules={FIELDS_RULES.acceptDataProcessing}
          >
            <Checkbox>
              <p>Соглашаюсь на обработку персональных данных</p>
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
    </AuthLayout >
  );
};

export default RegistrationForm;